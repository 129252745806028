import React, { useEffect, useState,Fragment, useRef } from "react";
import "@google/model-viewer/lib/model-viewer"
import { Link } from "react-router-dom";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "model-viewer": ModelViewerJSX &
                React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}
interface ModelViewerJSX {
    src: string;
    poster?: string;
    iosSrc?: string;
    seamlessPoster?: boolean;
    autoplay?: boolean;
    environmentImage?: string;
    exposure?: string;
    interactionPromptThreshold?: string;
    shadowIntensity?: string;
    ar?: boolean;
    arModes?: string;
    autoRotate?: boolean;
    cameraControls?: boolean;
    cameraOrbit?: string;
    alt?: string;
    sx?: any;
}
export  const Landing3D:React.FC = () => {

    const clickHandler = () => {
        console.log("Clicked");
    }
    const glbSrc = "/assets/Masterplan.glb";

    // @ts-ignore
    return (
        <div style={{
            backgroundImage: "url('images/gennius-landing-image.jpg')", 
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: '100vh',
            width: '100vw',}}>
            {/* <model-viewer src={glbSrc} ar ar-modes="webxr scene-viewer quick-look" exposure="0.8" camera-controls  environment-image="/images/environment-image.jpg" field-of-view="200" min-camera-orbit="-180deg 10deg 200m" max-camera-orbit="180deg 88deg 2500m"  tone-mapping="aces" poster="poster.webp" shadow-intensity="20" shadow-softness="0" autoplay> */}
            {/* <button className="Hotspot" slot="hotspot-1" data-surface="17 0 3047 3048 3049 0.585 0.057 0.357" data-visibility-attribute="visible">
                <div className="HotspotAnnotation"><Link style={{color: 'black'}} to="/story">Story</Link></div>
            </button><button className="Hotspot" slot="hotspot-2" data-surface="25 0 80 78 77 0.517 0.402 0.081" data-visibility-attribute="visible">
                <div className="HotspotAnnotation"><Link style={{color: 'black'}} to="/world">Soledad</Link></div>
            </button><button className="Hotspot" slot="hotspot-3" data-surface="18 0 4865 4866 4867 0.135 0.776 0.089" data-visibility-attribute="visible">
                <div className="HotspotAnnotation">flatGreen</div>
            </button><button className="Hotspot" slot="hotspot-4" data-surface="17 0 3098 3100 3096 0.216 0.626 0.159" data-visibility-attribute="visible">
                <div className="HotspotAnnotation">flatRed</div>
            </button><button className="Hotspot" slot="hotspot-5" data-surface="21 0 37 35 34 0.316 0.255 0.429" data-visibility-attribute="visible">
                <div className="HotspotAnnotation">sign</div>
            </button><button className="Hotspot" slot="hotspot-6" data-surface="22 0 74 75 76 0.614 0.280 0.106" data-visibility-attribute="visible">
                <div className="HotspotAnnotation">rear</div>
            </button>
            <div className="progress-bar hide" slot="progress-bar">
                <div className="update-bar"></div>
            </div> */}
            {/* </model-viewer> */}
        </div>
      );
}