import { createSlice } from "@reduxjs/toolkit";

export interface User {
    timestamp: string,
    playerId: string,
    displayName: string,
    avatarUrl: string,
    avatarProfilePic: string | null,
    radius: number,
    height: number,
    defaultAvatarType: string,
    colour: string
}

interface UsersOnlineState {
    users: User[]
}
const initialState: UsersOnlineState = {
    users: [
        {   
            displayName: "System", 
            playerId: "54321",
            timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), 
            avatarUrl: "https://models.readyplayer.me/66a7a3fc922f759d2bf57a7b.png",
            avatarProfilePic: null,
            radius: 0,
            height: 0,
            defaultAvatarType: "A",
            colour: "green"
    }]
}

export const usersOnlineSlice = createSlice({
    name: "usersOnline",
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload
        }
    }
});

export const { setUsers } = usersOnlineSlice.actions;

export default usersOnlineSlice.reducer;